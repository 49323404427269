@use '../../../scss/' as *;

.ProjectsPopUp {
  border-radius: inherit;

  height: calc(100dvh - var(--border-width) * 4);
  overflow: auto;

  display: grid;
  grid-template-rows: 1fr 10fr;
  
  header {
    margin-block: var(--popup-header-margin-block);
    text-align: center;
    line-height: 1.25;
  }

  ul {
    margin-inline: auto;
    padding-inline: 1rem;
    
    max-width: 95%;
    overflow: auto;
    
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-content: flex-start;
    gap: 1rem;
  }

  @include media-query-new(tablet-new) {
    ul {
      height: 90%;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media-query-new(desktop-medium-new) {
    ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include media-query-new(desktop-large-new) {
    ul {
    }
  }
}

/* MEDIA QUERIES USED:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-new(desktop-large-new)
*/
