@use 'sass:map';

$breakpoints: (
  tablet-new: 35rem,
  // 560px
  desktop-small-new: 56.25rem,
  // 900px
  desktop-medium-new: 70rem,
  // 1120px
  desktop-large-new: 85rem,
  // 1360px
);

@mixin media-query-new($key) {
  $size: map.get($breakpoints, $key);

  @media (min-width: $size) {
    @content;
  }
}

@mixin media-query-landscape-mobile {
  @media (max-height: 500px) {
    @content;
  }
}

/* mixins not currently in use: */
@mixin media-maxWidth-orientation($key, $orientation) {
  $size: map.get($breakpoints, $key);

  @media (max-width: $size) and (orientation: $orientation) {
    @content;
  }
}

@mixin media-maxWidth-minHeight($key1, $key2) {
  $width: map.get($breakpoints, $key1);
  $height: map.get($breakpoints, $key2);

  @media (max-width: $width) and (min-height: $height) {
    @content;
  }
}

@mixin media-landscape($key) {
  $size: map.get($breakpoints, $key);

  @media (max-height: $size) {
    height: 100%;
    overflow: auto;
    @content;
  }
}

@mixin media-mobile-landscape() {
  @media (max-width: 900px) and (max-height: 500px) and (orientation: landscape) {
    @content;
  }
}