@use '../../../scss/' as *;

.Compass {
  grid-column: 10 / 13;
  grid-row: 1 / 25;

  align-self: flex-end;

  display: grid;
  height: 20vw;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  
  @include media-query-new(tablet-new) {
    // grid-row: 20 / 25;
  }

  @include media-query-new(desktop-small-new) {
    scale: 75%;
    transform: translate(20%, 20%);
    // grid-row: 18 / 25;
  }

  @include media-query-landscape-mobile {
    scale: 50%;
    transform: translate(50%, 50%);
  }
}

.OuterRingContainer {
  grid-column: 3 / 5;
  grid-row: 3 / 5;
}

.OuterRing {
  background: linear-gradient(
    25deg,
    var(--silver-dark),
    var(--green),
    var(--yellow-three),
    var(--silver-dark),
    var(--green)
  );
  background-size: 30% 30%;
  border: 0.3vw solid var(--black-one);
  border-radius: 50%;

  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.OuterCircle {
  background: linear-gradient(
    135deg,
    var(--blue-mid-three),
    var(--blue-mid-one),
    var(--blue-mid-two),
    var(--blue-mid-one)
  );
  background-blend-mode: soft-light;
  background-size: 100% 100%;

  animation: gradient 10s ease infinite;
  -moz-animation: gradient 10s ease infinite;
  -webkit-animation: gradient 10s ease infinite;

  border: 0.3vw solid var(--black-one);
  border-radius: 50%;
  height: 6.25vw;
  width: 6.25vw;

  position: relative;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  25% {
    background-position: 50% 0;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 75%;
  }
  100% {
    background-position: 0 50%;
  }
}

@-moz-keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  25% {
    background-position: 50% 0;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 75%;
  }
  100% {
    background-position: 0 50%;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  25% {
    background-position: 50% 0;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 75%;
  }
  100% {
    background-position: 0 50%;
  }
}

.InnerRing {
  grid-column: 3 / 5;
  grid-row: 3 / 5;
  background: linear-gradient(
    125deg,
    var(--silver-dark),
    var(--green),
    var(--yellow-three),
    var(--silver-dark)
  );
  background-size: 50% 50%;
  border: 0.4vw solid var(--black-one);
  border-radius: 50%;

  height: 4.5vw;
  width: 4.5vw;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.InnerCircle {
  background: linear-gradient(
    135deg,
    var(--blue-mid-three),
    var(--blue-mid-one),
    var(--blue-mid-two),
    var(--blue-mid-one)
  );
  background-blend-mode: soft-light;
  background-size: 100% 100%;

  animation: gradient 10s ease infinite;
  -moz-animation: gradient 10s ease infinite;
  -webkit-animation: gradient 10s ease infinite;

  border: 0.35vw solid var(--black-one);
  border-radius: 50%;
  height: 2vw;
  width: 2vw;
}

.TopTriangle {
  grid-column: 3 / 5;
  grid-row: 2 / 5;
  border-top: 2vw solid transparent;
  border-right: 4vw solid transparent;
  border-bottom: 9vw solid var(--yellow-three);
  border-left: 4vw solid transparent;
  height: 50%;
  width: 50%;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.BottomTriangle {
  grid-column: 3 / 5;
  grid-row: 2 / 5;
  border-top: 9vw solid var(--yellow-three);
  border-right: 4vw solid transparent;
  border-bottom: 2vw solid transparent;
  border-left: 4vw solid transparent;
  height: 50%;
  width: 50%;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.LeftTriangle {
  grid-column: 2 / 6;
  grid-row: 4 / 6;
  border-top: 3vw solid transparent;
  border-right: 8vw solid var(--yellow-three);
  border-bottom: 3vw solid transparent;
  border-left: 2vw solid transparent;
  height: 50%;
  width: 100%;

  position: relative;
  transform: translate(-35%, -50%);
}

.RightTriangle {
  grid-column: 4 / 8;
  grid-row: 4 / 6;
  border-top: 3vw solid transparent;
  border-right: 2vw solid transparent;
  border-bottom: 3vw solid transparent;
  border-left: 8vw solid var(--yellow-three);
  height: 50%;
  width: 100%;

  position: relative;
  transform: translate(-20%, -50%);
}

/* media queries:
  @include media-query-new(desktop-small-new)
  @include media-query-landscape-mobile
*/
