@use './breakpoints' as *;

%island-centering {
  display: flex;
  align-items: center;
  justify-content: center;
}

%outline {
  & > * {
    outline: pink 2px dashed;
  }

  @include media-query-new(tablet-new) {
    & > * {
      outline: red 2px dashed;
    }
  }
  
  @include media-query-new(desktop-small-new) {
    & > * {
      outline: lime 2px dashed;
    }
  }

  @include media-query-new(desktop-medium-new) {
    & > * {
      outline: aliceblue 2px dashed;
    }
  }

  @include media-query-new(desktop-large-new) {
    & > * {
      outline: gray 2px dashed;
    }
  }

  @include media-query-landscape-mobile() {
    & > * {
      outline: yellow 2px dashed;
    }
  }
}