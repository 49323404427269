@use '../../../scss/' as *;

.ProfilePopUp {
  --profile-popup-padding-top: 1rem;
  --profile-popup-padding-bottom: 1rem;

  padding-top: var(--profile-popup-padding-top);
  padding-bottom: var(--profile-popup-padding-bottom);
  padding-inline: 1.5rem;

  height: 100%;

  display: grid;
  grid-template-rows: auto auto;

  header {
    text-align: center;
    font-size: var(--continent-popup-header);

    // photo
    img {
      border-radius: 50%;
      border-style: solid;
      border-color: var(--popup-text-color);
      border-width: 2px;
      width: 15%;
      min-width: 7rem;
      max-width: 20rem;
      margin-top: 1rem;
    }

    // name
    h2 {
      line-height: 1.25;
    }

    // contact info
    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      

      a {
        color: var(--profile-popup-link);
  
        &:hover {
          color: var(--profile-popup-link-hover);
        }
      }
    }
  }

  // bio
  & > div {
    grid-template-rows: 2 / 3;
    height: calc(100% - var(--profile-popup-padding-bottom));
    overflow: auto;
    
    border: 1px solid var(--popup-text-color);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.25rem 0.01rem var(--popup-text-color);

    margin-top: 1rem;
    padding: 1rem;

    font-size: var(--continent-popup-text);
    text-align: justify;
    

    p + p {
      margin-top: 0.75rem;
    }
  }

  @include media-query-landscape-mobile {
    display: flex;
    align-items: center;
    gap: 1rem;
    
    --profile-popup-padding-bottom: 2rem;

    header {

      img {
        min-width: 6rem;
      }

      h2 {
        line-height: 1;
        text-wrap: nowrap;
      }
    }

    & > div {
      height: 100%;
    }
  }
}

/* media queries:
  @include media-query-new(tablet-new)
  @include media-query-landscape-mobile
*/