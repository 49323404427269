@use '../../../scss/' as *;

.Title {
  grid-column: 1 / 13;
  grid-row: 1 / 5;
  padding-top: 2rem;

  header {
    color: var(--gold);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-family: Arial, Helvetica, sans-serif;
    font-family: var(--continent-font-bold);
    text-align: center;

    h1 {
      font-size: clamp(4rem, 7vw, 7rem);
      font-weight: 500;
      line-height: 0.8;
    }
  
    h2 {
      font-size: clamp(2rem, 4vw, 4rem);
      font-weight: 100;
      display: none;

      span {
        display: none;
      }
    }
  }

  @media (min-height: 700px) {
    header {
      h2 {
        display: inline;
        
        span {
          display: inline;
        }
      }
    }
  }


  @include media-query-new(tablet-new) {
   header {
    h1 {
      font-size: 5rem;
    }
    
    h2 {
      font-size: 3rem;
    }
   }
  }

  @include media-query-new(desktop-small-new) {
    grid-column: 4 / 10;
  }

  @include media-query-new(desktop-medium-new) {
    grid-row: 1 / 6;
  }

  @include media-query-new(desktop-large-new) {
    grid-column: 5 / 9;

    header {
      h1 {
        font-size: 6rem;
      }
      
      h2 {
        font-size: 3.5rem;
      }
     }
  }

  @include media-query-landscape-mobile {
    grid-column: 4 / 10;
    grid-row: 1 / 7;
    padding-top: 1.5rem;

    header {
      h1 {
        font-size: 3.75rem;
      }
    }
  }
}

/* media queries used:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-new(desktop-medium-new)
  @include media-query-new(desktop-large-new)
  @include media-query-landscape-mobile
*/