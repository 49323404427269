@use '../../../scss/' as *;

.MajorArcanaSummary {
  background: var(--major-arcana-gradient);
  color: var(--gold-dark);

  h2 {
    font-size: 1.5rem;
    text-align: center;
  }
}
