@use '../../scss/' as *;

.Continent {
  border-radius: inherit;
  
  width: 80%;
  min-width: fit-content;
  max-width: 8rem;

  height: 100%;
  max-height: 4.5rem;
  
  text-align: center;

  
  // OUTER COASTLINE
  box-shadow: var(--coastline-shadow);

  padding: var(--outer-coastline-padding);

  background: var(--outer-coastline-bkgd);
  background-size: var(--outer-coastline-bkgd-size);
  animation: coastal-wave 20s ease infinite both;
  -moz-animation: coastal-wave 20s ease infinite both;
  -webkit-animation: coastal-wave 20s ease infinite both;
  -o-animation: coastal-wave 20s ease infinite both;
  -ms-animation: coastal-wave 20s ease infinite both;

  @keyframes coastal-wave {
    0% {
      background-position: 0 100%;
    }
    25% {
      background-position: 50% 0;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 75%;
    }
    100% {
      background-position: 0 100%;
    }
  }
  
  @-moz-keyframes coastal-wave {
    0% {
      background-position: 0 50%;
    }
    25% {
      background-position: 50% 0;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 75%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  
  @-webkit-keyframes coastal-wave {
    0% {
      background-position: 0 50%;
    }
    25% {
      background-position: 50% 0;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 75%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  
  // TRANSFORMS ON FOCUS AND HOVER
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;

  &:focus-within {
    scale: 1.075;
  }
  
  // RESTRICT TRANSFORM TO DEVICES THAT HOVER
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      scale: 1.075;
    }
  }

  div {
    // INNER COASTLINE
    border-radius: inherit;
    background: var(--inner-coastline);
    
    padding: var(--inner-coastline-padding);
    height: 100%;

    div {
      // LAND
      border: var(--continent-border);
      border-radius: inherit;
      
      background: var(--continent-bkgd);
      background-size: 115% 115%;
      
      font-family: var(--continent-font-bold);
      
      min-width: fit-content;
      height: 100%;
      padding: 0.5rem 1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      // CONTINENT FONT SIZE FOR TITLE
      span {
        font-size: var(--continent-title-size);
      }

      @include media-query-new(desktop-small-new) {
        padding: 0.8rem 1.2rem;
      }
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  
    &:focus-within {
      transform: none;
      box-shadow: 0 0 1vw 0.75vw var(--focus-hover-highlight);
    }
  
    &:hover {
      transform: none;
      box-shadow: 0 0 1vw 0.75vw var(--focus-hover-highlight);
    }
  }

  @include media-query-new(tablet-new) {
    max-width: 10rem;
    max-height: 6rem;
  }

  @include media-query-new(desktop-small-new) {
    max-width: 13rem;
    max-height: 7rem;
  }
  
  @include media-query-new(desktop-medium-new) {
    max-width: 15rem;
    max-height: 9rem;
  }
  
  @include media-query-new(desktop-large-new) {
    max-width: 18rem;
    max-height: 10rem;
  }

  @include media-query-landscape-mobile {
    padding: .25rem;

    max-width: 10rem;

     div {
      padding: .25rem;
      div span {
        // background-color : pink;
        font-size: 2rem;
      }
     }
  }
}


/* media queries:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-medium-new)
  @include media-query-landscape-mobile
  @media (prefers-reduced-motion: reduce)
*/