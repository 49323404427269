@use '../../../scss/' as *;

// grid item to hold Legend
.Legend {
  grid-column: 8 / 14;
  grid-row: 5 / 14;

  padding-block: 0.5rem;
  padding-inline: 1rem;

  max-width: 20rem;

  display: flex;
  align-items: center;
  
  position: relative;
  z-index: 2;
  
  // Legend
  & > div {
    background: linear-gradient(
      180deg,
      var(--yellow-one),
      var(--yellow-two),
      var(--yellow-three),
      var(--yellow-four),
      var(--yellow-five)
    );

    border: 0.15rem solid var(--black-two);
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem 0.15rem gold;

    padding-block: 0.75rem;
    padding-inline: 1.25rem 0.5rem;
    max-height: 95%;
    overflow: auto;

    section + section {
      margin-top: 1rem;
    }
    
    section {
      h3 {
        width: fit-content;
        margin-inline: auto;
        padding-inline-end: 1.5rem;

        font-family: var(--continent-font-bold);
        font-size: 1.75rem;
        text-decoration: underline;
        text-decoration-thickness: from-font;
      }

      ul + ul {
        margin-top: .5rem;
      }

      ul {
        ul {   
          display: flex;
          flex-direction: column;
          gap: .1rem;
  
          font-size: 0.85rem;
  
          li {
            list-style-type: "•\002008";
            
          }
        }
      }
    }

    // media queries for Legend contents
    @include media-query-new(tablet-new) {
      section {
        h3 {
          font-size: 2rem;
        }

        ul ul {
          flex-direction: row;
          flex-wrap: wrap;
          gap: .75rem;
        }
      }
    }

    @include media-query-new(desktop-large-new) {
      padding-inline: 1.5rem 1rem;

      section {
        ul ul {
          gap: .9rem;
          font-size: 0.9rem;
        }
      }
    }

    @include media-query-landscape-mobile {
      section {
        h3 {
          font-size: 1.5rem;
        }
        ul ul {
          font-size: 0.75rem;
        }
      }
    }
  }

  // media queries for grid container
  @include media-query-new(tablet-new) {
    grid-column: 9 / 13;
    grid-row: 5 / 14;
  }

  @include media-query-new(desktop-small-new) {
    grid-column: 10 / 13;
    grid-row: 5 / 17;
    justify-self: center;
  }
  
  @include media-query-landscape-mobile {
    grid-column: 10 / 13;
    grid-row: 4 / 16;
    max-height: 100%;
    justify-self: center;
  }
}

/* media queries used:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-new(desktop-large-new)
  @include media-query-landscape-mobile
*/