@use '../../scss/' as *;

.Ocean {
  grid-column: 1 / 13;
  grid-row: 1 / 25;

  background: linear-gradient(
    90deg,
    var(--dark-blue-two),
    var(--dark-blue-four),
    var(--dark-blue-two),
    var(--dark-blue-four),
    var(--dark-blue-two)
  );

  background-blend-mode: soft-light;
  background-size: 400% 400%;

  animation: ocean-wave 20s ease infinite;
  -moz-animation: ocean-wave 20s ease infinite;
  -webkit-animation: ocean-wave 20s ease infinite;
  -o-animation: ocean-wave 20s ease infinite both;
  -ms-animation: ocean-wave 20s ease infinite both;

  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(24, 1fr);

  position: relative;
  z-index: 0;
}

@keyframes ocean-wave {
  0% {
    background-position: 0 50%;
  }
  25% {
    background-position: 50% 0;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 75%;
  }
  100% {
    background-position: 0 50%;
  }
}

@-moz-keyframes ocean-wave {
  0% {
    background-position: 0 50%;
  }
  25% {
    background-position: 50% 0;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 75%;
  }
  100% {
    background-position: 0 50%;
  }
}

@-webkit-keyframes ocean-wave {
  0% {
    background-position: 0 50%;
  }
  25% {
    background-position: 50% 0;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 75%;
  }
  100% {
    background-position: 0 50%;
  }
}