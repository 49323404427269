@use '../../scss/' as *;

.OpenDetails {
  background-color: var(--silver-light);
  border-radius: inherit;
  border: 0.1rem solid var(--black-one);
  
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;

  color: var(--black-one);
  font-size: var(--project-summary-button-text);

  &:hover {
    cursor: pointer;
  }
}
