@use '../../scss/' as *;

.OuterCoastline {
  background: var(--outer-coastline-bkgd);
  background-size: 15% 15%;
  animation: coastal-wave 20s ease infinite both;
  -moz-animation: coastal-wave 20s ease infinite both;
  -webkit-animation: coastal-wave 20s ease infinite both;
  -o-animation: coastal-wave 20s ease infinite both;
  -ms-animation: coastal-wave 20s ease infinite both;

  border-radius: 50%;
  box-shadow: 0vw 0vw 1vw 1vw var(--coastline-shadow-color);

  padding: 0.2rem;

  width: 100%;
  max-width: 4rem;
  min-width: fit-content;

  height: 100%;
  max-height: 3.25rem;

  @include media-query-new(tablet-new) {
    max-width: 6rem;
    max-height: 4rem;
  }
  
  @include media-query-new(desktop-small-new) {
    max-width: 6rem;
    max-height: 4rem;
  }
  
  @include media-query-new(desktop-large-new) {
    max-width: 7rem;
    max-height: 4.5rem;
  }

  @include media-query-landscape-mobile {
    max-width: 4rem;
    max-height: 3rem;
  }
}

.OuterCoastline:focus-within,
.OuterCoastline:hover {
  box-shadow: 0px 0px 2px 5px var(--focus-hover-highlight);
}

.InnerCoastline {
  background: var(--inner-coastline);
  border-radius: inherit;

  padding: 0.25rem;

  width: 100%;
  height: 100%;
  

  @include media-query-landscape-mobile {
    padding: 0.1rem;
  }
}

.Island {
  border-radius: inherit;
  background: var(--continent-bkgd);
  background-size: 150% 150%;

  padding: 0.65rem 0.5rem;
  width: 100%;
  height: 100%;
  
  display: flex;
  
  font-size: 0.75rem;

  p {
    margin: auto;
  }

  @include media-query-new(tablet-new) {
    font-size: 1rem;
    padding: 0;
  }

  @include media-query-new(desktop-large-new) {
    font-size: 1.2rem;
  }

  @include media-query-landscape-mobile {
    font-size: 0.75rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .OuterCoastline:focus-within,
  .OuterCoastline:hover {
    box-shadow: none;
    scale: 1.15;
  }

  .OuterCoastline {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }
}

/* media queries used
@include media-query-new(tablet-new)
@include media-query-new(desktop-small-new)
@include media-query-new(desktop-large-new)
@media (prefers-reduced-motion: no-preference)
@include media-query-landscape-mobile
  */


  @keyframes coastal-wave {
    0% {
      background-position: 0 100%;
    }
    25% {
      background-position: 50% 0;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 75%;
    }
    100% {
      background-position: 0 100%;
    }
  }
  
  @-moz-keyframes coastal-wave {
    0% {
      background-position: 0 50%;
    }
    25% {
      background-position: 50% 0;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 75%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  
  @-webkit-keyframes coastal-wave {
    0% {
      background-position: 0 50%;
    }
    25% {
      background-position: 50% 0;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 75%;
    }
    100% {
      background-position: 0 50%;
    }
  }