@use '../../../scss/' as *;

.WeatherBeatsSummary {
  background-image: url(../../../assets/WeatherBeats_mobile.jpg);
  background-position: 50% 55%;
  background-repeat: no-repeat;
  background-size: cover;

  color: var(--black-one);
}
