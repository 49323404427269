@use '../../../scss/' as *;

.GlowingColorsDetails {
  background: var(--glowing-colors-gradient);
  color: var(--blue-light-one);
  
  @include media-query-new(tablet-new) {
    border-radius: var(--popup-border-radius);
  }
}

/* media queries used:
  @include media-query-new(tablet-new)
*/