@use '../../../scss/' as *;

.MajorArcanaDetails {
  background: var(--major-arcana-gradient);
  color: var(--gold-dark);

  img {
    width: 25rem;
  }
 
  @include media-query-new(tablet-new) {
    border-radius: var(--popup-border-radius);
  }
}

/* media queries used:
  @include media-query-new(tablet-new)
*/