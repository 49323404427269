@use '../../scss/' as *;

.PopUp {
  background: var(--popup-bkgd);

  height: calc(100% - var(--border-width) * 2);
  width: calc(100% - var(--border-width) * 2);
  max-width: 60rem;

  position: absolute;
  inset: 0;
  margin: auto;
  
  color: var(--popup-text-color);
  font-family: var(--main-font);

  /* styles for title in popup header */
  & header h2 {
    font-size: var(--popup-title-size);
    font-family: var(--continent-font-bold);
    font-weight: 400;
  }

  & p {
    line-height: 1.25;
  }

  @include media-query-new(tablet-new) {
    border-radius: var(--popup-border-radius);
    border-color: inherit;
    border-style: solid;
    width: 90%;
    height: 90%;
  }
}

/* media queries used
  @include media-query-new(tablet-new)
*/