@use '../../scss/' as *;

.LinkedIn {
  grid-column: 8 / 12;
  grid-row: 18 / 21;

  z-index: 2;

  @extend %island-centering;

  @include media-query-new(tablet-new) {
    grid-column: 9 / 11;
    grid-row: 18 / 20;
  }

  @include media-query-new(desktop-small-new) {
    grid-column: 6 / 8;
    grid-row: 20 / 24;
  }

  @include media-query-landscape-mobile {
    grid-column: 9 / 11;
    grid-row: 19 / 23;
  }
}

/* media queries used
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-landscape-mobile
*/