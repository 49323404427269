@use '../../../scss/' as *;

.CartographyContinent {
  --continent-cartography-fs-multiplier: 0.75;

  grid-column: 2 / 8;
  grid-row: 18 / 23;

  display: flex;
  
  /* unique styles for Continent component that only apply to CartographyContinent */
  & > div {
    transform: rotate(-15deg);
    margin: auto;
    border-radius: 60% 40% 60% 35%;

    &:hover {
      transform: rotate(-15deg);
      scale: 1.075;
    }

    /* unique styles for CartographyContinent title SPAN */
    a div span {
      display: inline-block;
      padding-block: 0.5rem;
      transform: rotate(-7.5deg);
      font-size: calc(var(--continent-title-size) * var(--continent-cartography-fs-multiplier));

      @include media-query-landscape-mobile {
        --continent-title-size: 1.25rem;
        padding-block: 0.25rem;
      }
    }
  }

  @include media-query-new(tablet-new) {
    grid-column: 5 / 9;
    grid-row: 20 / 23;

    div {
      height: fit-content;
    }
  }

  @include media-query-new(desktop-small-new) {
    --continent-cartography-fs-multiplier: 0.6;

    grid-column: 8 / 11;
    grid-row: 18 / 25;
  }

  @include media-query-landscape-mobile {
    --continent-cartography-fs-multiplier: 0.75;

    grid-column: 6 / 9;
    grid-row: 19 / 24;


    & > div {
      max-width: 50%;

      a div span {
        --continent-title-size: 1.5rem;
        padding: 0;
      }
    }
    
  }
}

/* media queries used
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
*/