@use '../../scss/' as *;

.ProjectSummary {
  border-radius: 0.5rem;
  max-width: 25rem;
  margin-inline: auto;

  font-size: var(--project-summary-title);


  h2 {
    font-size: 1.5rem;
  }

  & > div {
    border-radius: inherit;
    border-style: solid;
    border-width: thin;
    border-color: var(--white-two);;

    padding: 1rem 0.5rem;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 4fr auto;
    justify-items: center;
    align-items: center;

    height: 100%;
    overflow: auto;

    & > div {
      height: 100%;
      padding: 0.5rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      text-align: justify;
    }

    p {
      font-size: 1.1rem;
    }
  }
}