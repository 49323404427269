@use '../../../scss/' as *;

.ScaleContainer {
  grid-column: 1 / 10;
  grid-row: 23 / 25;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  position: relative;
  z-index: -1;

  color: var(--white-two);

  & > div:last-child {
    div + div {
      visibility: hidden;
      width: 0;
    }
  }

  @include media-query-new(tablet-new) {
    grid-column: 1 / 9;
    justify-content: flex-start;
    padding-left: 2rem;
  }

  @include media-query-new(desktop-small-new) {
    grid-column: 1 / 6;
    padding-left: 4rem;
  }

  @include media-query-new(desktop-large-new) {
    grid-column: 1 / 6;

    padding-inline: 3vw 0;

    align-items: flex-start;
  }

  @include media-query-landscape-mobile {
    grid-column: 1 / 6;
    grid-row: 22 / 25;
  }
}

.ScaleNumber {
  position: relative;
  left: -0.5rem;

  @include media-query-new(tablet-new) {
    left: -0.5rem;
  }
}

.ScaleNumberFirst {
  left: -0.25rem;
}

.ScaleBlockFirst {
  border-left: 0.2vw var(--gray-one) solid;
}

.ScaleBlockLast {
  border-right: 0.2vw var(--gray-one) solid;
}

.ScaleBlockBlack,
.ScaleBlockWhite {
  border-top: 0.2vw var(--gray-one) solid;
  border-bottom: 0.2vw var(--gray-one) solid;

  height: 0.5rem;
  width: 2rem;

  @include media-query-new(tablet-new) {
    height: 0.75rem;
    width: 2.5rem;
  }

  @include media-query-new(desktop-large-new) {
    height: 1rem;
    width: 4.25rem;
  }

  @include media-query-landscape-mobile {
    height: 0.5rem;
    width: 1.9rem;
  }
}

.ScaleBlockBlack {
  background-color: var(--black-one);
}

.ScaleBlockWhite {
  background-color: var(--white-one);
}

/* media queries used:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-new(desktop-large-new)
*/