@use '../../scss/' as *;

.Email {
  grid-column: 10 / 13;
  grid-row: 15 / 19;

  z-index: 2;

  @extend %island-centering;
  
  @include media-query-new(tablet-new) {
    grid-column: 10 / 13;
    grid-row: 16 / 18;
  }

  @include media-query-new(desktop-small-new) {
    grid-column: 7 / 9;
    grid-row: 17 / 20;
  }
  
  @include media-query-new(desktop-large-new) {
    grid-column: 7 / 9;
  }

  
  @include media-query-landscape-mobile {
    grid-column: 10 / 13;
    grid-row: 17 / 21;
  }
}

/* media queries used
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-landscape-mobile
*/