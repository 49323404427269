@use '../../../scss/' as *;

.CartographyPopUp {
  text-align: center;
  padding-block: 1rem;

  height: calc(100dvh - var(--border-width) * 2);
  overflow: auto;

  img {
    border: 2px solid var(--bordercolor-one);
    border-radius: 0.25rem;
    background-repeat: no-repeat;
  
    width: 15rem;
    height: 10rem;
    object-fit: cover;

    @include media-query-new(tablet-new) {
      width: 9rem;
      height: 6rem;
    }
    
    @include media-query-new(desktop-small-new) {
      width: 12rem;
      height: 8rem;
    }
  
    @include media-query-new(desktop-medium-new) {
      width: 15rem;
      height: 10rem;
    }
  
    @media (max-height: 700px) {
      width: 9rem;
      height: 6rem;
    }
  }
  
  & > div {
    height: 90%;
    overflow: auto;
    padding-inline: 2rem;
    
    section {
      font-size: var(--continent-popup-text);

      text-align: justify;
      margin-top: 0.5rem;

      span {
        font-family: var(--continent-font-bold);
        font-size: 1.75rem;
      }
  
      p + p {
        margin-top: 2rem;
        text-align: center;
      }
    }

    section + section {
      margin-top: 1rem;
  
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      max-width: 35rem;
      margin-inline: auto;

      @include media-query-new(desktop-small-new) {
        max-width: 40rem;
      }
      
      @include media-query-new(desktop-medium-new) {
        max-width: 100%;
      }
    }
  }

  @include media-query-new(tablet-new) {
    display: grid;
    grid-template-rows: 1fr 10fr;
  }
}

/* media queries used
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-new(desktop-medium-new)
*/