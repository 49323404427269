@use '../../../scss/' as *;

.Border {
  background: linear-gradient(
    130deg,
    var(--bordercolor-one),
    var(--bordercolor-two),
    var(--bordercolor-one),
    var(--bordercolor-two),
    var(--bordercolor-one)
  );

  background-blend-mode: soft-light;
  background-size: 3% 2%;

  height: 100dvh;
  padding: var(--border-width);

  overflow: hidden;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(24, 1fr);

  @include media-query-new(tablet-new) {
    background-size: 2% 1.5%;
  }

  @include media-query-new(desktop-small-new) {
    background-size: 1.25% 1.5%;
  }

  @include media-query-new(desktop-medium-new) {
    background-size: 1% 1.5%;
  }
}

/* media queries used
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-new(desktop-medium-new)
*/