@use '../../scss/' as *;

.ClosePopup {
  border-radius: 50%;
  padding: 0.25rem 0.5rem;

  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 2;

  font-size: 1rem;
}
