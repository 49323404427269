@use '../../scss/' as *;

.ProjectLinks {
  font-size: var(--project-details-links);
  font-weight: 550;

  display: grid;
  grid-template-columns: 9fr auto 9fr;
  grid-template-rows: 1;
  gap: 0.5rem;

  span {
    text-align: center;
  }

  span:first-child {
    text-align: right;
  }
  
  span:last-child {
    text-align: left;
  }

  span {
    border-radius: 5rem;;

    a {
      color: inherit;
      padding: 0.25rem 0.5rem;
      outline-width: thin;
      outline-style: solid;
      outline-color: transparent;
      transition: outline-color 250ms ease-in;

      &:focus-visible,
      &:hover {
        outline-color: inherit;
      }
    }
  }
}
