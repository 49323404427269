@use '../../../scss/' as *;

.BorderCorner {
  grid-column: 1 / 13;
  grid-row: 1 / 25;

  width: 1rem;
  aspect-ratio: 1 / 1;

  position: relative;
  z-index: 1;

  @include media-query-new(tablet-new) {
    height: 1.25rem;
    width: 1.25rem; 
  }

  @include media-query-new(desktop-small-new) {
    height: 1.5rem;
    width: 1.5rem;    
  }
}

/* media queries used:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
*/