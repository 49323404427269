@use '../../../scss/' as *;

.ResumeContinent {
  grid-column: 1 / 7;
  grid-row: 14 / 18;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 1;

  & div {
    border-radius: 520vw 270vw 495vw 190vw;
  }

  @include media-query-new(tablet-new) {
    grid-column: 2 / 6;
    grid-row: 16 / 20;
  }

  @include media-query-new(desktop-small-new) {
    grid-column: 2 / 6;
    grid-row: 13 / 19;
  }

  @include media-query-landscape-mobile {
    grid-column: 3 / 6;
    grid-row: 15 / 21;
  }
}

/* media queries:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
*/