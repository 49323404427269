@use '../../../scss/' as *;

.RootRPGDetails {
  background: var(--root-rpg-gradient);
  color: var(--black-one);
  
  & > h4 {
    position: relative;

    &::before {
      content: 'NextJS';
      position: absolute;
      left: -6.25rem;
    }
    &::after {
      content: 'Sass';
      position: absolute;
      left: 1rem;
    }
  }

  @include media-query-new(tablet-new) {
    border-radius: var(--popup-border-radius);
  }
}

/* media queries used:
  @include media-query-new(tablet-new)
*/