@use '../../scss/' as *;

.ProjectDetails {
  border-radius: var(--popup-border-radius);
  height: 100%;
  width: 100%;
  max-width: 50rem;

  margin: auto;
  overflow: auto;

  position: absolute;
  inset: 0%;
  z-index: 1;

  font-family: var(--main-font);

  & > div {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    height: 100%;

    border-color: inherit;
    border-radius: inherit;
    border-style: solid;
    border-width: medium;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    overflow: auto;
    
    h3 {
      font-size: var(--project-details-title);
      line-height: var(--project-details-title);
      text-align: center;
    }

    h4 {
      max-width: 85%;
      font-size: var(--project-details-tech);
      font-weight: 500;
      text-align: center;
    }

    h3, p {
      padding-inline: 2rem;
    }

    p {
      max-width: 60ch;
      font-size: var(--project-details-text);
      line-height: 1.25;
      text-align: justify;
    }

    a {
      color: inherit;
    }
  }

  @include media-query-new(tablet-new) {
    width: 90%;
    height: 75%;

    div {
      padding-top: 1rem;
    }
  }

  @include media-query-new(desktop-small-new) {
    height: 90%;

    div {
      padding-top: 1.5rem;
    }
  }
}

/* media queries used:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
*/