@use '../../../scss/' as *;

.BeHumanDetails {
  background: var(--be-human-gradient);
  color: var(--black-two);

  img {
    border: 0.15rem solid var(--black-one);
    border-radius: 50%;
    width: 12rem;
    margin-top: 1.5rem;
  }
}