@use '../../../scss/' as *;

.ProjectsContinent {
  grid-column: 1 / 8;
  grid-row: 5 / 8;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 2;

  & div {
    border-radius: 84vw 75vw 51vw 38vw;
  }

  @include media-query-new(tablet-new) {
    grid-column: 2 / 5;
    grid-row: 5 / 9;
  }

  @include media-query-new(desktop-small-new) {
    grid-column: 1 / 5;
    grid-row: 5 / 11;
  }

  @include media-query-landscape-mobile {
    grid-column: 1 / 4;
    grid-row: 7 / 13;
  }
}

/* media queries:
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
*/