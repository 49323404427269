@use '../scss/' as *;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

body {
  min-width: 350px;
}

// @font-face {
//   font-family: 'Tangerine';
//   src: url(../fonts/tangerine/Tangerine_Regular.ttf);
// }

@font-face {
  font-family: 'Tangerine Bold';
  src: url(../fonts/tangerine/Tangerine_Bold.ttf);
}

:root {
  
  --blue-light-one: hsl(220, 90%, 90%);
  --blue-light-two: hsl(250, 90%, 90%);
  --blue-light-three: hsl(250, 90%, 80%);

  --blue-mid-one: hsl(214, 82%, 45%);
  --blue-mid-two: hsl(234, 75%, 45%);
  --blue-mid-three: hsl(246, 75%, 46%);
  --dark-blue-one: hsl(253, 77%, 29%);
  --dark-blue-two: hsl(240, 100%, 27%);
  --dark-blue-three: hsl(240, 60%, 25%);
  --dark-blue-four: hsl(258, 100%, 14%);

  --blue-hue: 200;
  --blue-sat: 40%;
  --blue-light-75: 75%;
  --blue-light-70: 70%;
  --blue-light-65: 65%;
  --blue-light-60: 60%;
  --blue-light-55: 55%;

  --blue-mid-hue: 220;
  --blue-mid-sat: 45%;
  --blue-mid-light-30: 30%;
  --blue-mid-light-25: 25%;
  --blue-mid-light-20: 20%;
  --blue-mid-light-15: 15%;

  --blue-dark-hue: 260;
  --blue-dark-sat: 80%;
  --blue-dark-light-30: 30%;
  --blue-dark-light-20: 20%;
  --blue-dark-light-15: 15%;
  --blue-dark-light-05: 5%;

  --black-gold-hue: 0;
  --black-gold-sat: 0%;
  --black-gold-light-15: 15%;
  --black-gold-light-10: 10%;
  --black-gold-light-05: 5%;

  --orange-hue: 25;
  --orange-sat: 65%;
  --orange-light-55: 55%;
  --orange-light-50: 50%;
  --orange-light-45: 45%;
  --orange-light-40: 40%;
  --orange-light-35: 35%;

  --blue-coastal-one: hsl(203, 68%, 61%);
  --blue-coastal-two: hsl(203, 56%, 43%);
  --blue-coastal-three: hsl(203, 65%, 65%);
  --blue-coastal-four: hsl(203, 47%, 52%);
  --blue-coastal-five: hsl(203, 59%, 52%);
  --blue-coastal-six: hsl(203, 52%, 60%);

  --yellow-one: hsl(60, 89%, 64%);
  --yellow-two: hsl(60, 83%, 77%);
  --yellow-three: hsl(60, 86%, 72%);
  --yellow-four: hsl(60, 92%, 67%);
  --yellow-five: hsl(60, 83%, 77%);
  --yellow-six: hsl(60, 100%, 50%);
  --gold: hsl(50, 100%, 50%);
  --gold-dark: hsl(25, 66%, 69%);

  --black-one: hsl(0, 0%, 0%);
  --black-two: hsl(0, 0%, 5%);
  --brown: hsl(0, 59%, 41%);
  --gray-one: hsl(0, 0%, 50%);
  --green: hsl(120, 100%, 25%);
  --green-dark: hsl(90, 60%, 6%, 0.8);
  --silver-light: hsl(0, 0%, 95%);
  --silver-dark: hsl(0, 0%, 75%);
  --white-one: hsl(0, 0%, 100%);
  --white-two: hsl(0, 0%, 90%);

  --tan-continent-one: hsl(34, 49%, 62%);
  --tan-continent-two: hsl(45, 51%, 72%);
  --tan-continent-three: hsl(34, 49%, 62%);
  --tan-continent-four: hsl(35, 25%, 65%);

  --bordercolor-one: var(--silver-light);
  --bordercolor-two: var(--dark-blue-three);
  --border-width: 0.5rem;

  --coastline-shadow: 0vw 0vw 2vw 1.5vw var(--dark-blue-two);

  --outer-coastline-bkgd: linear-gradient(
    90deg,
    var(--blue-coastal-one),
    var(--blue-coastal-two),
    var(--blue-coastal-three),
    var(--blue-coastal-four)
    );
    --outer-coastline-bkgd-size: 15% 15%;
    --outer-coastline-padding: 0.2rem;
  
    --inner-coastline: linear-gradient(
      90deg,
      var(--blue-coastal-one),
      var(--blue-coastal-five),
      var(--blue-coastal-three),
      var(--blue-coastal-six)
    );
  --inner-coastline-padding: 0.2rem;

  --continent-border-color: var(--brown);
  --continent-border: 0.2vw solid var(--continent-border-color);
  --continent-bkgd: linear-gradient(
    30deg,
    var(--tan-continent-one),
    var(--tan-continent-one),
    var(--tan-continent-two),
    var(--tan-continent-three),
    var(--tan-continent-two),
    var(--tan-continent-four)
  );

  // --continent-font: 'Tangerine';
  --continent-font-bold: 'Tangerine Bold';
  --main-font: Arial, sans-serif;
  --continent-title-size: clamp(2.25rem, 5vw, 6rem);

  --continent-popup-header: 1.25rem;
  --continent-popup-text: 1.2rem;
  --popup-title-size: 4rem;
  
  /* new mobile-first styles */
  --continent-header-margin-block: 1rem;
  --popup-header-margin-block: 1rem;
  --popup-border-radius: 0rem;

  --popup-bkgd: var(--black-two);
  --popup-text-color: var(--white-two);

  --profile-popup-link: var(--blue-light-three);
  --profile-popup-link-hover: var(--blue-light-two); 

  --project-summary-title: 1.1rem;
  --project-summary-text: 1.1rem;
  --project-summary-button-text: 1rem;

  --project-details-title: 2.5rem;
  --project-details-tech: 1.75rem;
  --project-details-text: 1.25rem;
  --project-details-links: 1.25rem;

  --map-title: 3rem;

  --focus-hover-highlight: var(--yellow-six);

  @include media-query-new(tablet-new) {
    --continent-title-size: clamp(2.25rem, 6vw, 5rem);
    --popup-border-radius: 0.75rem;
    --popup-title-size: 5rem;
  }

  @include media-query-new(desktop-small-new) {
    --outer-coastline-padding: 0.4rem;
    --inner-coastline-padding: 0.5rem;
  }

  @include media-query-landscape-mobile {
    --continent-title-size: clamp(2.25rem, 5vw, 6rem);
    --popup-title-size: 3rem;
    --continent-popup-header: 1rem;
    --popup-header-margin-block: 0.75rem;
  }

  /* project background gradients */
  --be-human-gradient: linear-gradient(
    180deg,
    hsl( var(--blue-hue), var(--blue-sat), var(--blue-light-75)),
    hsl(var(--blue-hue), var(--blue-sat), var(--blue-light-70)),
    hsl(var(--blue-hue), var(--blue-sat), var(--blue-light-65)),
    hsl(var(--blue-hue), var(--blue-sat), var(--blue-light-60)),
    hsl(var(--blue-hue), var(--blue-sat), var(--blue-light-55))
  );

  --ebb-gradient: linear-gradient(
    180deg,
    hsl( var(--blue-mid-hue), var(--blue-mid-sat), var(--blue-mid-light-30)),
    hsl(var(--blue-mid-hue), var(--blue-mid-sat), var(--blue-mid-light-25)),
    hsl(var(--blue-mid-hue), var(--blue-mid-sat), var(--blue-mid-light-20)),
    hsl(var(--blue-mid-hue), var(--blue-mid-sat), var(--blue-mid-light-15))
  );
  
  --glowing-colors-gradient: linear-gradient(
    180deg,
    hsl( var(--blue-dark-hue), var(--blue-dark-sat), var(--blue-dark-light-30)),
    hsl(var(--blue-dark-hue), var(--blue-dark-sat), var(--blue-dark-light-20)),
    hsl(var(--blue-dark-hue), var(--blue-dark-sat), var(--blue-dark-light-15)),
    hsl(var(--blue-dark-hue), var(--blue-dark-sat), var(--blue-dark-light-05))
  );
  
  --major-arcana-gradient: linear-gradient(
    180deg,
    hsl( var(--black-gold-hue), var(--black-gold-sat), var(--black-gold-light-15)),
    hsl(var(--black-gold-hue), var(--black-gold-sat), var(--black-gold-light-10)),
    hsl(var(--black-gold-hue), var(--black-gold-sat), var(--black-gold-light-05))
  );

  --root-rpg-gradient: linear-gradient(
    180deg,
    hsl( var(--orange-hue), var(--orange-sat), var(--orange-light-55)),
    hsl(var(--orange-hue), var(--orange-sat), var(--orange-light-50)),
    hsl(var(--orange-hue), var(--orange-sat), var(--orange-light-45)),
    hsl(var(--orange-hue), var(--orange-sat), var(--orange-light-40)),
    hsl(var(--orange-hue), var(--orange-sat), var(--orange-light-35))
  );
}

a {
  color: var(--green-dark);
  text-decoration: none;
  outline: none;
  border-radius: inherit;
}

a:hover {
  color: var(--dark-blue-one);
}

/* 
media queries used
  @include media-query-new(tablet-new)
  @include media-query-new(desktop-small-new)
  @include media-query-landscape-mobile
*/