@use '../../scss/' as *;

.MapDetails {
  height: 100%;
  padding-block: 1rem;

  display: grid;
  grid-template-rows: auto 1fr;

  font-family: var(--continent-font-bold);
  font-size: var(--map-title);
  text-align: center;

  p {
    margin-bottom: 1rem;
    line-height: 1;
  }

  div {
    overflow: auto;
    line-height: 0;

    img {
      border-radius: 1.25rem;
      max-width: 75%;
      max-height: 100%;
    }
  }
}